export const utils = {
  getMaxId (arr, key) {
    if (!arr || arr.length === 0) {
      return 0
    }

    let nextobj = parseInt(arr[0][key])

    for (let i = 0; i < arr.length; i++) {
      if (parseInt(arr[i][key]) > nextobj) {
        nextobj = parseInt(arr[i][key])
      }
    }
    return nextobj
  },
  getZeroFillNumber (len) {
    const randomNum = parseInt(Math.random() * 100)
    let data = randomNum
    if (randomNum < 10 ** (len - 1)) {
      let str = ''
      for (let i = 0; i < len - 1; i++) {
        str += '0'
      }
      data = str + randomNum
    }
    return data
  }
}
