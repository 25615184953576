<template>
  <b-modal :id="'modal-'+btn.id" centered :title="btn.title" v-model="componentModal[btn.id]" @hide="handleModalHide" @show="handleModalShow" :size="btn.id === 'hiddenCatch' ? 'xl' : ''">
    <div class="media" v-if="btn.id === 'people'">
      <div class="media-aside align-self-start">
        <img :src="characterList[modalData.character].src" class="mr-3 avatar-80"/>
      </div>
      <div v-for="(item,idx) in characterList" :key="idx">
        <b-form-radio class="custom-radio-color" inline v-model="modalData.character" :color="item.color" name="color" :value="item.value">{{ item.name }}</b-form-radio>
      </div>
    </div>
    <div class="media" v-if="btn.id === 'text'">
      <textarea v-model="modalData.text" class="form-control"></textarea>
    </div>
    <div class="media" v-if="btn.id === 'video'">
      <div class="media-aside align-self-start">
        <video :src="modalData.img" class="mr-3 avatar-80"></video>
      </div>
      <div v-for="(v,i) in modalFiles" :key="i">
        <input type="file" v-on:change="changeModalImg" class="form-control-file" accept="video/*"/>
      </div>
    </div>
    <div class="media" v-if="btn.id === 'image'">
      <div class="media-aside align-self-start">
        <img :src="modalData.img" class="mr-3 avatar-80"/>
      </div>
      <div v-for="(v,i) in modalFiles" :key="i">
        <input type="file" v-on:change="changeModalImg" class="form-control-file" accept="image/*"/>
      </div>
    </div>
    <div class="media" v-if="btn.id === 'quiz'">
      <textarea v-model="modalData.quiz.question" class="form-control"></textarea>
    </div>
    <div class="media" v-if="btn.id === 'quiz'">
      <div class="media mt-4">
        <div class="media-aside align-self-start">
          <div class="mt-2">답변&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div class="media-aside align-self-start">
          <div v-for="(item,idx) in ['O', 'X']" :key="idx">
            <b-form-radio class="custom-radio-color" inline v-model="modalData.quiz.answer" name="ox" :value="item">{{ item }}</b-form-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="media2" v-if="btn.id === 'hiddenCatch'">
      <div class="media" style="justify-content : center" v-show="modalData.img && modalData.img2">
        <h4>왼쪽 이미지에서 다른그림을 선택해주세요 (최대 5개까지 선택 가능합니다)</h4>
        <button type="button" class="btn btn-danger" @click="clearHiddenCircles">다른그림 초기화</button>
      </div>
      <div class="media">
        <div v-for="(v,i) in modalFiles" :key="'modal1_'+i" style="flex:1">
          <input type="file" v-on:change="changeModalImg" class="form-control-file" accept="image/*"/>
        </div>
        <div v-for="(v,i) in modalFiles" :key="'modal2_'+i" style="flex:1">
          <input type="file" v-on:change="changeModalImg2" class="form-control-file" accept="image/*"/>
        </div>
      </div>
      <div class="media">
        <div class="media-aside align-self-start" style="flex:1; position:relative;" @click="selectCheckPoint">
          <img :src="modalData.img" class="avatar-800 hidden-catch-image" />
          <div class="hidden-circle" v-for="(v,i) in modalData.hiddenCircles" :key="'circle_'+i" :style="getStyle(v)"></div>
        </div>
        <div class="media-aside align-self-start" style="flex:1; position:relative;"  @click="selectCheckPoint">
          <img :src="modalData.img2" class="avatar-800 hidden-catch-image"/>
          <div class="hidden-circle" v-for="(v,i) in modalData.hiddenCircles" :key="'circle_'+i" :style="getStyle(v)"></div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'VModal',
  props: {
    modalData: Object,
    characterList: Array,
    btn: Object,
    componentModal: Object,
    modalFiles: Array
  },
  emits: [
    'modal:hide',
    'modal:show'
  ],
  components: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted () {
    // this.emitEvent('mount')
  },
  beforeUnmount () {
    // this.emitEvent('destroy')
  },
  methods: {
    clearHiddenCircles () {
      this.modalData.hiddenCircles = []
    },
    getStyle (v) {
      return {
        position: 'absolute',
        left: (v.left - 20) + 'px',
        top: (v.top - 20) + 'px',
        width: '40px',
        height: '40px',
        border: '3px solid red',
        borderRadius: '40px',
        zIndex: 10
      }
    },
    handleModalShow (e) {
      this.emitEvent('modal:show', e)
    },
    handleModalHide (e) {
      this.emitEvent('modal:hide', e)
    },
    emitEvent (eventName, event) {
      this.$emit(eventName, event)
    },
    changeModalImg (e) {
      this.modalData.file = e.target.files
      this.modalData.img = URL.createObjectURL(e.target.files[0])
    },
    changeModalImg2 (e) {
      this.modalData.file2 = e.target.files
      this.modalData.img2 = URL.createObjectURL(e.target.files[0])
    },
    selectCheckPoint (e) {
      if (!(this.modalData.img && this.modalData.img2)) {
        return
      }
      if (this.modalData.hiddenCircles.length >= 5) {
        return
      }
      if (e.target.className === 'hidden-circle') {
        return
      }
      const item = {
        left: e.offsetX,
        top: e.offsetY
      }
      this.modalData.hiddenCircles.push(item)
    }
  }
}
</script>
