<template>
  <div>
      <div v-show="item.type === 'people'" class="component charactor-component" :component_id="item.componentId" @mousedown="emitEvent('click')" :style="style">
        <img :src="getSrc(item)"/>
      </div>
      <div v-show="item.type === 'text'" class="component text-component" :component_id="item.componentId" @mousedown="emitEvent('click')" :style="style">
        <textarea class="form-control" v-model="item.text"></textarea>
      </div>
      <div v-show="item.type === 'video'" class="component video-component" :component_id="item.componentId" @mousedown="emitEvent('click')" :style="style">
        <video :src="getSrc(item)"></video>
      </div>
      <div v-show="item.type === 'audio'" class="component audio-component" :component_id="item.componentId" @mousedown="emitEvent('click')" :style="style">
        <audio :src="getSrc(item)" controls></audio>
      </div>
      <div v-show="item.type === 'image'" class="component image-component" :component_id="item.componentId" @mousedown="emitEvent('click')" :style="style">
        <img :src="getSrc(item)"/>
      </div>
      <div v-show="item.type === 'hiddenCatch'" class="component hiddenCatch-component" :component_id="item.componentId" @mousedown="emitEvent('click')" :style="style">
        <div class="catch-div">
          <img :src="getSrc(item, 0)"/>
          <div class="hidden-circle" v-for="(v,i) in item.circles" :key="'circle_'+i" :style="getStyle(v, item)"></div>
        </div>
        <div class="catch-div">
          <img :src="getSrc(item, 1)"/>
          <div class="hidden-circle" v-for="(v,i) in item.circles" :key="'circle_'+i" :style="getStyle(v, item)"></div>
        </div>
      </div>
      <div v-show="item.type === 'quiz'" class="component text-component" :component_id="item.componentId" @mousedown="emitEvent('click')" >
        <span class="question_num">Q. </span> <span class="question_data">{{item.question}}</span>
      </div>
  </div>
</template>

<script>

export default {
  name: 'VComponent',
  props: {
    item: Object,
    characterList: Array,
    fileGroupList: Array,
    fileList: Array
  },
  emits: [
    'click'
  ],
  components: {

  },
  data () {
    return {
      // item : this.item,
      // characterList : this.characterList,
      // fileGroupList: this.fileGroupList,
      // fileList: this.fileList
    }
  },
  computed: {
    style () {
      return {
        zIndex: this.item.zIndex,
        opacity: this.item.opacity
      }
    }
  },
  watch: {

  },
  mounted () {
    // this.emitEvent('mount')
  },
  beforeUnmount () {
    // this.emitEvent('destroy')
  },
  methods: {
    emitEvent (eventName) {
      this.$emit(eventName, this.item)
    },
    getStyle (v, item) {
      return {
        position: 'absolute',
        left: ((item.width / 500) * v.left - 20 * (item.width / 500)) / 2 + 'px',
        top: ((item.height / 600) * v.top - 20 * (item.height / 600)) + 'px',
        width: 40 * (item.width / 500) / 2 + 'px',
        height: 40 * (item.width / 500) / 2 + 'px',
        border: '3px solid red',
        borderRadius: '1000px',
        zIndex: 10
      }
    },
    getSrc (item, index) {
      let url = ''
      const seq = index || 0
      if (item.type === 'video' || item.type === 'audio' || item.type === 'image') {
        const fileData = this.getFile(item.componentId)
        if (fileData.length === 0) {
          return ''
        }
        if (fileData[0].state === 'I') {
          url = fileData[0].tmpUrl
        } else {
          url = fileData[0].url
          url = url.replace('http://127.0.0.1:8000', '')
          // console.log(url)
        }
      }
      if (item.type === 'hiddenCatch') {
        const fileData = this.getFile(item.componentId)
        if (fileData.length === 0) {
          return ''
        }

        if (fileData[seq].state === 'I') {
          url = fileData[seq].tmpUrl
        } else {
          url = fileData[seq].url
          url = url.replace('http://127.0.0.1:8000', '')
        }
      }
      if (item.type === 'people') {
        url = this.characterList[item.character].src
      }
      // console.log(url)
      return url
    },
    getFile (componentId) {
      const fileList = []
      const fileGroupList = this.fileGroupList.filter(itm => itm.componentId === componentId)
      for (let i = 0; i < fileGroupList.length; i++) {
        for (let j = 0; j < this.fileList.length; j++) {
          if (fileGroupList[i].fileId === this.fileList[j].fileId) {
            fileList.push(this.fileList[j])
          }
        }
      }
      return fileList
    }
  }
}
</script>
