import axios from './index'

export const CHECK_SESSION = 'projects/session_check/'
export const GET_PROJECT = 'projects/'
export const GET_CONTENTS_LIST = 'contents/datalist/'
export const GET_FILEGROUP_LIST = 'file_group/datalist/'
export const GET_FILE = 'files/'
export const GET_COMPONENT_LIST = 'components/datalist/'
export const GET_ANIMATION_LIST = 'animations/datalist/'
export const UPLOAD_FILES = 'files/uploads/'
export const INSERT_COMPONENTS = 'components/inserts/'
export const UPDATE_COMPONENTS = 'components/updates/'
export const DELETE_COMPONENTS = 'components/deletes/'
export const INSERT_CONTENTS = 'contents/inserts/'
export const UPDATE_CONTENTS = 'contents/updates/'
export const DELETE_CONTENTS = 'contents/deletes/'
export const INSERT_FILEGROUPS = 'file_group/inserts/'
export const UPDATE_FILEGROUPS = 'file_group/updates/'
export const DELETE_FILEGROUPS = 'file_group/deletes/'
export const INSERT_ANIMATIONS = 'animations/inserts/'
export const UPDATE_ANIMATIONS = 'animations/updates/'
export const DELETE_ANIMATIONS = 'animations/deletes/'

export default {
  axiosCheckSession (token) {
    axios.defaults.headers.common['X-CSRFToken'] = token
    return axios.post(CHECK_SESSION)
  },
  axiosGetProject (id) {
    return axios.get(GET_PROJECT + id + '/')
  },
  axiosGetContentsList (projectId) {
    return axios.get(GET_CONTENTS_LIST + projectId + '/')
  },
  axiosGetFileGroupList (projectId) {
    return axios.get(GET_FILEGROUP_LIST + projectId + '/')
  },
  axiosGetFile (id) {
    return axios.get(GET_FILE + id + '/')
  },
  axiosGetComponentList (projectId) {
    return axios.get(GET_COMPONENT_LIST + projectId + '/')
  },
  axiosGetAnimationList (projectId) {
    return axios.get(GET_ANIMATION_LIST + projectId + '/')
  },
  axiosUploadFiles (formData) {
    return axios.post(UPLOAD_FILES, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  axiosInsertComponents (datas) {
    return axios.post(INSERT_COMPONENTS, datas)
  },
  axiosUpdateComponents (datas) {
    return axios.put(UPDATE_COMPONENTS, datas)
  },
  axiosDeleteComponents (datas) { // 한번에 여러개를 지우기에 post 사용
    return axios.post(DELETE_COMPONENTS, datas)
  },
  axiosInsertContents (datas) {
    return axios.post(INSERT_CONTENTS, datas)
  },
  axiosUpdateContents (datas) {
    return axios.put(UPDATE_CONTENTS, datas)
  },
  axiosDeleteContents (datas) { // 한번에 여러개를 지우기에 post 사용
    return axios.post(DELETE_CONTENTS, datas)
  },
  axiosInsertFileGroups (datas) {
    return axios.post(INSERT_FILEGROUPS, datas)
  },
  axiosUpdateFileGroups (datas) {
    return axios.put(UPDATE_FILEGROUPS, datas)
  },
  axiosDeleteFileGroups (datas) { // 한번에 여러개를 지우기에 post 사용
    return axios.post(DELETE_FILEGROUPS, datas)
  },
  axiosInsertAnimations (datas) {
    return axios.post(INSERT_ANIMATIONS, datas)
  },
  axiosUpdateAnimations (datas) {
    return axios.put(UPDATE_ANIMATIONS, datas)
  },
  axiosDeleteAnimations (datas) { // 한번에 여러개를 지우기에 post 사용
    return axios.post(DELETE_ANIMATIONS, datas)
  }
}
