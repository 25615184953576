<template>
  <b-modal id="modal-animation" centered title="Animation" v-model="componentModal.animation" @hide="handleModalHide" @show="handleModalShow" size="xl">
    <div class="media">
      <div class="media mt-4">
        <div class="media-aside align-self-start">
          <div class="mt-2">대상&nbsp;&nbsp;&nbsp;</div>
        </div>
        <div class="media-aside align-self-start">
          <select v-model="selectedAnimationTarget" class="form-control" @change="selectAnimaitionTarget">
            <option v-for="(v, i) in $options.filters.selectedIdDataList(components, 'contentsId', selectedContentsList[0])" :value="v" :key="i">{{v.componentId}}</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="selectedAnimationTarget && selectedAnimationTarget.type !== 'people' && selectedAnimationTarget.type !== 'text' && selectedAnimationTarget.type !== 'quiz'">
      <div class="media">
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            <div class="mt-2">옵션</div>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2">left</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.left"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">top</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.top" />
          </div>
        </div>
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">width</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.width"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">height</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.height" />
          </div>
        </div>
      </div>
      <div class="media">
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2">z-index</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.zIndex"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">opacity</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.opacity" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedAnimationTarget && selectedAnimationTarget.type === 'people'">
      <div class="media">
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            <div class="mt-2">옵션</div>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2">left</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.left"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">top</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.top" />
          </div>
        </div>
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">width</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.width"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">height</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.height" />
          </div>
        </div>
      </div>
      <div class="media">
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2">x</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.x"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">y</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.y" />
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">z</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.z"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">r</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.r" />
          </div>
        </div>
      </div>
      <div class="media">
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2">z-index</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.zIndex"/>
          </div>
          <div class="media-aside align-self-start">
            <div class="mt-2 ml-2">opacity</div>
          </div>
          <div>
            <input type="number" class="form-control" v-model.number="animationData.status.opacity" />
          </div>
        </div>
      </div>
      <div class="media">
        <div class="media mt-4">
          <div class="media-aside align-self-start">
            <div class="mt-2">애니메이션</div>
          </div>
          <div>
            <select v-model="animationData.status.emote" class="form-control">
              <option v-for="(v, i) in modelEmotes" :value="v" :key="i">{{v}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="media" v-if="selectedAnimationTarget && selectedAnimationTarget.type !== 'text' && selectedAnimationTarget.type !== 'quiz'" >
      <div class="media mt-4">
        <div class="media-aside align-self-start">
          <div class="mt-2">
            애니메이션 지속시간
          </div>
        </div>
        <div class="media-aside align-self-start">
          <input type="number" class="form-control" v-model.number="animationData.duration"/>
        </div>
      </div>
    </div>
    <div class="media" v-if="selectedAnimationTarget">
      <div class="media mt-4">
        <div class="media-aside align-self-start">
          <div class="mt-2">
            시작전 딜레이
          </div>
        </div>
        <div class="media-aside align-self-start">
          <input type="number" class="form-control" v-model.number="animationData.prevDelay"/>
        </div>
      </div>
    </div>
    <div class="media" v-if="selectedAnimationTarget">
      <div class="media mt-4">
        <div class="media-aside align-self-start">
          <div class="mt-2">시작타이밍</div>
        </div>
        <div class="media-aside align-self-start">
          <select v-model="animationData.timing.target" class="form-control" >
            <option v-for="(v, i) in $options.filters.selectedIdDataList(animationList, 'contentsId', selectedContentsList[0])" :value="v.animationId" :key="i">{{v.animationId}}</option>
          </select>
        </div>
        <div class="media-aside align-self-start">
          <select v-model="animationData.timing.action" class="form-control">
            <option v-for="(v, i) in animationTimings" :value="v" :key="i">{{v}}</option>
          </select>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'VAnimationModal',
  props: {
    componentModal: Object,
    animationData: Object,
    selectedAnimationTarget: Object,
    animationTimings: Array,
    selectedContentsList: Array,
    animationList: Array,
    components: Array,
    modelEmotes: Array
  },
  emits: [
    'modal:hide',
    'modal:show'
  ],
  components: {

  },
  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  mounted () {
    // this.emitEvent('mount')
  },
  beforeUnmount () {
    // this.emitEvent('destroy')
  },
  methods: {
    handleModalShow (e) {
      this.emitEvent('modal:show', e)
    },
    handleModalHide (e) {
      this.emitEvent('modal:hide', e)
    },
    emitEvent (eventName, event) {
      this.$emit(eventName, event)
    },
    selectAnimaitionTarget () {
      const item = {
        width: this.selectedAnimationTarget.width,
        height: this.selectedAnimationTarget.height,
        left: this.selectedAnimationTarget.left,
        top: this.selectedAnimationTarget.top,
        x: 0,
        y: 3,
        z: 10,
        r: 0,
        opacity: this.selectedAnimationTarget.opacity,
        zIndex: this.selectedAnimationTarget.zIndex,
        state: 'Idle',
        emote: '',
        angry: 0,
        surprised: 0,
        sad: 0
      }
      this.animationData.targetComponent.componentId = this.selectedAnimationTarget.componentId
      this.animationData.targetComponent.type = this.selectedAnimationTarget.type
      this.animationData.status = item
    }
  }
}
</script>
