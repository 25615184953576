<template>
  <div>
    <div v-if="selectedComponents.length > 0 && selectedComponents[0].type !== 'text' && selectedComponents[0].type !== 'quiz'">
      <div class="media mt-4 id">
        <div class="media-aside align-self-start">
          <div class="mt-2">ID</div>
        </div>
        <div>
          <div class="mt-2">{{selectedComponents[0].componentId}}</div>
        </div>
      </div>
      <div class="media mt-4 xy">
        <div class="media-aside align-self-start">
          <div class="mt-2">X:</div>
        </div>
        <div>
          <input type="number" class="form-control x" v-model.number="selectedComponents[0].left"/>
        </div>
        <div class="media-aside align-self-start">
          <div class="mt-2 ml-2">Y:</div>
        </div>
        <div>
          <input type="number" class="form-control y" v-model.number="selectedComponents[0].top" />
        </div>
      </div>
      <div class="media mt-4 wh">
        <div class="media-aside align-self-start">
          <div class="mt-2">W:</div>
        </div>
        <div>
          <input type="number" class="form-control w" v-model.number="selectedComponents[0].width" />
        </div>
        <div class="media-aside align-self-start">
          <div class="mt-2 ml-2">H:</div>
        </div>
        <div>
          <input type="number" class="form-control h" v-model.number="selectedComponents[0].height" />
        </div>
      </div>
      <div class="media mt-4 z">
        <div class="media-aside align-self-start">
          <div class="mt-2">Z-INDEX:</div>
        </div>
        <div>
          <input type="number" class="form-control z" v-model.number="selectedComponents[0].zIndex"/>
        </div>
      </div>
      <div class="media mt-4 opacity">
        <div class="media-aside align-self-start">
          <div class="mt-2">OPACITY:</div>
        </div>
        <div>
          <input type="number" class="form-control opacity" v-model.number="selectedComponents[0].opacity"/>
        </div>
      </div>
    </div>
    <div v-if="selectedComponents.length > 0 && selectedComponents[0].type === 'text'">
      <div class="media mt-4 id">
        <div class="media-aside align-self-start">
          <div class="mt-2">ID</div>
        </div>
        <div>
          <div class="mt-2">{{selectedComponents[0].componentId}}</div>
        </div>
      </div>
      <div class="media mt-4 content">
        <div class="media-aside align-self-start">
          <div class="mt-2">내용</div>
        </div>
        <div>
          <textarea class="form-control" v-model="selectedComponents[0].text"></textarea>
        </div>
      </div>
      <div class="media mt-4">
        <div>
          <div class="ai-curator-bottom-language">
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'ko-KR', 'btn-danger' : selectedComponents[0].lang !== 'ko-KR'}" @click="changeLang('ko-KR')"><img src="../components/img/korea.png"><p>한국어</p></button>
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'en-US', 'btn-danger' : selectedComponents[0].lang !== 'en-US'}" @click="changeLang('en-US')"><img src="../components/img/england.png"><p>ENGLISH</p></button>
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'zh-CN', 'btn-danger' : selectedComponents[0].lang !== 'zh-CN'}" @click="changeLang('zh-CN')"><img src="../components/img/china.png"><p>中國語</p></button>
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'ja-JP', 'btn-danger' : selectedComponents[0].lang !== 'ja-JP'}" @click="changeLang('ja-JP')"><img src="../components/img/japan.png"><p>日本語</p></button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedComponents.length > 0 && selectedComponents[0].type === 'quiz'">
      <div class="media mt-4 id">
        <div class="media-aside align-self-start">
          <div class="mt-2">ID</div>
        </div>
        <div>
          <div class="mt-2">{{selectedComponents[0].componentId}}</div>
        </div>
      </div>
      <div class="media mt-4 question">
        <div class="media-aside align-self-start">
          <div class="mt-2">문제</div>
        </div>
        <div>
          <textarea class="form-control" v-model="selectedComponents[0].question"></textarea>
        </div>
      </div>
      <div class="media mt-4 answer">
        <div class="media-aside align-self-start">
          <div class="mt-2">답변</div>
        </div>
        <div>
          <div v-for="(item,idx) in ['O', 'X']" :key="idx">
            <b-form-radio class="custom-radio-color" inline v-model="selectedComponents[0].answer" name="ox" :value="item">{{ item }}</b-form-radio>
          </div>
        </div>
      </div>
      <div class="media mt-4 number">
        <div class="media-aside align-self-start number">
          <div class="mt-2 ml-2">opacity</div>
        </div>
        <div>
          <input type="number" class="form-control opacity" v-model.number="selectedComponents[0].opacity" />
        </div>
      </div>
      <div class="media mt-4">
        <div>
          <div class="ai-curator-bottom-language">
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'ko-KR', 'btn-danger' : selectedComponents[0].lang !== 'ko-KR'}" @click="changeLang('ko-KR')"><img src="../components/img/korea.png"><p>한국어</p></button>
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'en-US', 'btn-danger' : selectedComponents[0].lang !== 'en-US'}" @click="changeLang('en-US')"><img src="../components/img/england.png"><p>ENGLISH</p></button>
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'zh-CN', 'btn-danger' : selectedComponents[0].lang !== 'zh-CN'}" @click="changeLang('zh-CN')"><img src="../components/img/china.png"><p>中國語</p></button>
            <button class="btn" :class="{'btn-primary' : selectedComponents[0].lang === 'ja-JP', 'btn-danger' : selectedComponents[0].lang !== 'ja-JP'}" @click="changeLang('ja-JP')"><img src="../components/img/japan.png"><p>日本語</p></button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedComponents.length === 0 && selectedContentsList.length > 0">
      <div class="media mt-4 code">
        <div class="media-aside align-self-start">
          <div class="mt-2">코드</div>
        </div>
        <div>
          <input type="text" class="form-control" v-model="selectedContentsList[0].code" @change="changeOption(selectedContentsList[0])"/>
        </div>
      </div>
      <div class="media mt-4 keyword">
        <div class="media-aside align-self-start">
          <div class="mt-2">키워드</div>
        </div>
        <div>
          <input type="text" class="form-control" v-model="selectedContentsList[0].keywords" @change="changeOption(selectedContentsList[0])"/>
        </div>
      </div>
      <div class="media mt-4 animation">
        <div class="media-aside align-self-start">
          <b-button v-b-modal.modal-animation variant="primary" class="add-button">Animation 추가</b-button>
        </div>
      </div>
      <div class="animation-wrap">
        <div v-for=" (item, index) in animationList.filter(itm=>itm.contentsId === selectedContentsList[0].contentsId && itm.state !== 'D')" class="media mt-4 animation-component" :key="'animation_'+index" @click="selectAnimation(item)"  :class="{active : selectedAnimationList.indexOf(item) > -1}">
          <div class="media-aside align-self-start animation-aside">
            <div class="">{{'Animation_'+(item.animationId)}}</div>
            <div>
              <button class="btn-primary pencil" data-toggle="tooltip" title="Amination 수정" style="margin-right : 10px" @click="showAnimationModal(item)"><i class="dripicons-pencil"></i></button>
              <button class="btn-danger trash" data-toggle="tooltip" title="Amination 삭제" @click="deleteAnimation(item)"><i class="las la-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VComponent',
  props: {
    selectedComponents: Array,
    animationList: Array,
    selectedContentsList: Array,
    selectedAnimationList: Array
  },
  emits: [
    'animation:show',
    'animation:select'
  ],
  components: {

  },
  data () {
    return {
      // item : this.item,
      // characterList : this.characterList,
      // fileGroupList: this.fileGroupList,
      // fileList: this.fileList
    }
  },
  computed: {

  },
  watch: {

  },
  mounted () {
    // this.emitEvent('mount')
  },
  beforeUnmount () {
    // this.emitEvent('destroy')
  },
  methods: {
    emitEvent (eventName, item) {
      this.$emit(eventName, item)
    },
    changeLang (lang) {
      this.selectedComponents[0].lang = lang
      if (this.selectedComponents[0].state !== 'I' && this.selectedComponents[0].state !== 'D') {
        this.selectedComponents[0].state = 'U'
      }
    },
    changeOption (obj) {
      if (obj.state !== 'I' && obj.state !== 'D') {
        obj.state = 'U'
      }
    },
    selectAnimation (item) {
      this.emitEvent('animation:select', item)
    },
    deleteAnimation (item) {
      this.emitEvent('animation:delete', item)
    },
    showAnimationModal (item) {
      this.emitEvent('animation:show', item)
    }
  }
}
</script>
